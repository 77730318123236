import React from "react"
import {Col, Container, Row, Button} from "react-bootstrap"
import {graphql, Link} from "gatsby";
import Layout from "../components/layout"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import Img from "gatsby-image"


function SinglePromo(props) {

    var debug = require('debug')('SinglePromo')

    debug(props.data.allFile.edges)

	// body --> https://www.freeformatter.com/json-escape.html#ad-output
    let stripped = props.data.promoJson.body
    props.data.promoJson.replacement.forEach((item) => {
        let re = new RegExp(item.placeholder, "g")
        stripped = stripped.replace(re, item.value)
    })
	
	
	let footerInfo = {
	  facebook: "https://www.facebook.com/cirotriassipianobar.triassi",
	  youtube: "https://www.youtube.com/channel/UC-9UuNz3P4JH9BAhwnCGxKA",
	  mobile: {
		  phone: "+393807040490",
		  txt: "(+39) 380 70 40 490"
	  },
	  email : {
		  mail: "cirotriassi@alice.it",
		  txt: "cirotriassi@alice.it",
		  message: "Vorrei bloccare la promo " + props.data.promoJson.title
	  },
	  wa : {
		  phone: "+393807040490",
		  txt: "(+39) 380 7040490",
		  message: "Vorrei bloccare la promo " + props.data.promoJson.title
	  },
	  me : "http://m.me/cirotriassipianobar.triassi?ref=info",
	  sms: {
		  phone: "+393807040490",
		  txt: "(+39) 380 7040490",
		  message: "Vorrei bloccare la promo " + props.data.promoJson.title
	  }
	}
	
    return (
        <Layout pageInfo={{pageName: "index"}} footerInfo={footerInfo}>
            <SEO title={props.data.promoJson.title} keywords={props.data.promoJson.keywords}/>
            <Container className="mt-5">
                <h1 className="mb-5">{props.data.promoJson.title}</h1>
				<Row>
                    <Col className="mb-5">
                        <Img className="d-block w-100" fluid={props.data.allFile.edges[0].node.childImageSharp.fluid}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{__html: stripped}}></div>
						<div className="mt-4 text-center">
                            <Link to={"/promo/" + props.data.promoJson.slug + "#contact"} className="link-no-style">
							    <Button variant="primary" size="lg" className="round-button large btn-block glow" active>Contattaci e blocca la promo</Button>
                            </Link>
                        </div>
                    </Col>
					
				</Row>
            </Container>
			<svg class="pointer" xmlns="https://www.w3.org/2000/svg" version="1.1" width="100%" viewBox="0 0 100 102" preserveAspectRatio="none"> <path d="M0 0 L50 100 L100 0 Z"></path></svg>
        </Layout>
    )
}

export default SinglePromo

export const query = graphql`
    query($id: String!, $img: String!) {
        promoJson(id: {eq: $id}) {
            id
            slug
            title
            keywords
            body
            replacement{
                placeholder
                value
            }
        }
        allFile (filter: {relativePath: {eq: $img}})  {
            edges {
                node {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
